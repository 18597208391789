<template>
  <div>
    <portal to="page-top-title">Apis</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'apis-new' }" icon="fa-plus" text>Add New</btn>
    </portal>

    <box-table title="List" url="/apis" :url-query="{ with: ['provider:id,name'] }" data-prop="apis" loader>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Provider</th>
          <th class="text-center">Sending Rate</th>
          <th class="text-center">Active</th>
          <th class="text-right">Created At</th>
          <th class="text-right">Updated At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="api in apis" :key="`apis-tr-${api.id}`" :value="api">
          <td>{{ api.id }}</td>
          <td>{{ api.name }}</td>
          <td>
            <router-link :to="{ name: 'apis-providers-single', params: { id: api.provider.id } }">{{ api.provider.name }}</router-link>
          </td>
          <td class="text-center">
            <badge title="TPS">{{ api.sending_rate }}</badge>
          </td>
          <td class="text-center">
            <badge-active :value="api.active"/>
          </td>
          <td class="text-right">{{ api.created_at }}</td>
          <td class="text-right">{{ api.updated_at }}</td>
          <td class="text-right">
            <btn :route="{ name: 'apis-edit', params: { id: api.id } }" icon="fa-edit">Edit</btn>
            <btn :route="{ name: 'apis-single', params: { id: api.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxTable from '@/views/components/data/BoxTable'
import Btn from '@/views/components/simple/Btn'
import Badge from '@/views/components/simple/Badge'
import BadgeActive from '@/views/components/simple/BadgeActive'

export default {
  name: 'ApisList',
  metaInfo: {
    title: 'Apis'
  },
  components: {
    BoxTable,
    Btn,
    Badge,
    BadgeActive
  },
  data () {
    return {
      apis: []
    }
  }
}
</script>
